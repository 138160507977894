






































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { format, date, QPopupProxy } from 'quasar'
import { TdInputTime, TdDate } from 'td-ui'

const { capitalize } = format
const { formatDate, getDateDiff } = date

@Component({
  components: {
    TdDate,
    TdInputTime,
    IconChevronDown: () => import('@/components/UI/icons/IconChevronDown.vue'),
  },
})
export default class MeetingDialogDateSelector extends Vue {
  @Prop({
    required: true,
    type: String,
  }) private value!: string

  @Prop({
    required: true,
    type: String,
  }) private readonly title!: string

  @Prop({
    type: Boolean,
  }) private readonly disable!: boolean

  @Prop({
    type: String,
  }) private readonly diffDate!: string

  @Ref() private readonly timesPopup!: QPopupProxy
  @Ref() private readonly datePopup!: QPopupProxy

  private popupOpen = false

  get time () {
    return formatDate(new Date(this.value), 'HH:mm')
  }

  get humanizedDate (): string {
    return capitalize(new Intl.DateTimeFormat(this.$i18n.locale, {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
    }).format(new Date(this.value)))
  }

  get times () {
    const numberOfIntervalsPerDay = 96
    let date = new Date(this.value)
    date.setMinutes(0)
    date.setHours(0)
    const times: { title: string, value: Date, time: string }[] = []
    for (let i = 0; i < numberOfIntervalsPerDay; i++) {
      const title = formatDate(date, 'HH:mm')
      times.push({
        title,
        value: date,
        time: title,
      })
      date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes() + 15,
      )
    }
    return times
  }

  get listTimes () {
    if (!this.diffDate) return this.times
    const index = this.times.findIndex(item => {
      const d = new Date(this.diffDate)
      if (item.time === formatDate(d, 'HH:mm')) {
        return true
      }
    })
    return this.times.slice(index + 1).map(item => ({
      ...item,
      ...{
        title: `${item.title} ${this.datesDiff(item.value)}`,
      },
    }))
  }

  private selectDate (v: string) {
    const d = new Date(v)
    const date = new Date(this.value)

    date.setFullYear(d.getFullYear(), d.getMonth(), d.getDate())

    this.$emit('input', date.toISOString())
    this.datePopup.hide()
  }

  private selectTime (time: string) {
    if (!time || time.length < 5) return
    const [h, m] = time.split(':')

    const date = new Date(this.value)
    date.setHours(+h, +m)

    this.$emit('input', date.toISOString())
    this.timesPopup.hide()
  }

  private selectTimeByDate (d: Date) {
    const date = new Date(this.value)

    date.setHours(d.getHours(), d.getMinutes(), 0, 0)

    this.$emit('input', date.toISOString())
    this.timesPopup.hide()
  }

  private datesDiff (endDate: Date) {
    if (!this.diffDate) return ''
    const startDate = new Date(this.diffDate)
    const minutesDiff = Math.abs(getDateDiff(startDate, endDate, 'minutes'))
    if (Math.abs(minutesDiff) < 60) {
      return `(${this.$t('meetings.meetingDialog.min', {
        min: minutesDiff,
      }).toString()})`
    }
    const hours = Math.floor(minutesDiff / 60)
    const hoursText = this.$tc('meetings.meetingDialog.hour', hours)
    const minutes = minutesDiff - (hours * 60)
    const minText = !minutes
      ? ''
      : `${this.$t('meetings.meetingDialog.min', { min: minutes }).toString()}`

    return `(${hoursText} ${minText})`
  }

  private show () {
    const el = document.querySelector('#currentItem')
    el && el.scrollIntoView()
    this.popupOpen = true
  }
}
